import { Button, makeStyles } from "@material-ui/core";
import {
  type SnackbarKey,
  type SnackbarOrigin,
  SnackbarProvider,
  useSnackbar,
  type VariantType,
} from "notistack";
import { useCallback, useMemo } from "react";
import * as React from "react";

/* ---------- PUBLIC APIS --------------- */

/**
 * Toast hook that is friendlier like what react-redux-toaster had
 */
export function useToast() {
  const ProviderContext = useSnackbar();

  const createToastCallback = useCallback(
    (variant: VariantType) =>
      (
        message: string,
        options?: {
          position?: SnackbarOrigin;
          dismissText?: string;
          onDismiss?: () => void;
        }
      ) => {
        if (!ProviderContext) {
          // silently fail if we don't have context
          return;
        }
        ProviderContext.enqueueSnackbar(message, {
          variant,
          anchorOrigin: options?.position, // Use the provided position or default to bottom center,
          action: (key) => (
            <Button
              style={{ color: "white" }}
              onClick={() => {
                options?.onDismiss?.();
                ProviderContext.closeSnackbar(key);
              }}
            >
              {options?.dismissText || "Dismiss"}
            </Button>
          ),
        });
      },
    [ProviderContext]
  );

  return useMemo(
    () => ({
      info: createToastCallback("info"),
      success: createToastCallback("success"),
      error: createToastCallback("error"),
      warning: createToastCallback("warning"),
    }),
    [createToastCallback]
  );
}

type Toaster = ReturnType<typeof useToast>;

export type WithToastProps = {
  toast: Toaster;
};

/**
 * HOC version of useToast, passes toaster as toast prop. See WithToastProps
 */
export function withToast<P extends object>(
  Component: React.ComponentType<P & WithToastProps>
): React.FC<P> {
  const WrappedComponent = (props: P) => {
    const toast = useToast();
    return <Component {...props} toast={toast} />;
  };
  const innerDisplayName =
    Component.displayName || Component.name || "Component";
  WrappedComponent.displayName = `withToast(${innerDisplayName})`;

  return WrappedComponent;
}

/* ---------- INTERNALS ----------- */

/**
 * Singleton toast provider, to be hosted below MUI theme provider, but only once
 * at the top of the app
 */
export function ToastProvider({ children }: React.PropsWithChildren<{}>) {
  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    if (!notistackRef.current) {
      return;
    }
    notistackRef.current.closeSnackbar(key);
  };

  const useStyles = makeStyles((theme) => ({
    success: {
      backgroundColor: "#43a047",
      "& > #notistack-snackbar": {
        maxWidth: "268px",
        alignItems: "flex-start",
      },
    },
    error: {
      backgroundColor: "#d32f2f",
      "& > #notistack-snackbar": {
        maxWidth: "268px",
        alignItems: "flex-start",
      },
    },
    warning: {
      backgroundColor: "#ff9800",
      "& > #notistack-snackbar": {
        maxWidth: "268px",
        alignItems: "flex-start",
      },
    },
    info: {
      backgroundColor: theme.palette.green?.[55] || "#2196f3",
      "& > #notistack-snackbar": {
        maxWidth: "268px",
        alignItems: "flex-start",
      },
    },
  }));

  const classes = useStyles();

  return (
    <SnackbarProvider
      classes={{
        variantError: classes.error,
        variantSuccess: classes.success,
        variantInfo: classes.info,
        variantWarning: classes.warning,
      }}
      ref={notistackRef}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      autoHideDuration={3000}
      // https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars
      // eslint-disable-next-line react/no-unstable-nested-components
      action={(key) => (
        <Button style={{ color: "white" }} onClick={onClickDismiss(key)}>
          Dismiss
        </Button>
      )}
    >
      <ToastStealerComponent />
      {children}
    </SnackbarProvider>
  );
}

// For backwards compatibility and calling within Redux. I think this will work?

// eslint-disable-next-line import/no-mutable-exports, @typescript-eslint/naming-convention
export let UNSAFE_globalToast: ReturnType<typeof useToast>;

function ToastStealerComponent() {
  const toast = useToast();

  UNSAFE_globalToast = toast;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
